import React, { Component } from 'react';
import Modal from 'react-modal';

class DTNModal extends Component {
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          ariaHideApp={false}
          className={{
            base: 'modal-base',
            afterOpen: 'modal-base_after-open',
            beforeClose: 'modal-base_before-close',
          }}
        >
          <div className="modal-body">
            <div>
              {this.props.codes && this.props.codes.dtn_list && this.props.codes.dtn_list.length ? (
                <div className="ins-table">
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th>DTN Code</th>
                        <th>DTN Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.codes.dtn_list.length
                        ? this.props.codes.dtn_list.map((row, i) => (
                            <tr key={i}>
                              <td>{row.dtn_code}</td>
                              <td>{row.dtn_name}</td>
                              <td>
                                <button onClick={() => this.props.handleExport(row.dtn_code, row.dtn_name)}>
                                  Export
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h6>DTN Codes unavailable</h6>
              )}
            </div>
          </div>
          <div className="modal-base-footer my-2 text-right">
            <button className="btn btn-danger mx-2" onClick={() => this.props.close()}>
              close
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DTNModal;
