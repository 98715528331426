import React, { Component } from 'react';
const isSSR = typeof window === 'undefined';
const CKEditor = !isSSR ? require('ckeditor4-react') : <div />;
import Modal from 'react-modal';
import sanitizeHtml from 'sanitize-html';
import $ from 'jquery';

let sanitizeConf = {
  allowedTags: [],
  allowedAttributes: {},
};

class PasteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorData: '',
      finalObj: { sections: [] },
    };
  }

  componentDidMount() {
    let tempObj = {};
    tempObj['tabsdata'];
    this.setState({ finalObj: { ...this.state.finalObj, ...tempObj } });
  }

  handleData = () => {
    let sectionObj = {
      title: '',
      rows: [
        {
          columns: [
            {
              colSize: 12,
              colValue: '',
              colType: 'editor',
            },
          ],
        },
      ],
    };

    let temp = this.state.editorData
      .replace(/<br\s*\/?>/gm, '\n')
      .replace(/<\/?span[^>]*>/g, '')
      .replace(/<\/?p[^>]*>/g, '');

    let a = temp.split('\n');

    let mykeys = [
      '##overview',
      '##title',
      '##body',
      '##section',
      '##instrument',
      '##id',
      '##entry',
      '##stop',
      '##target',
      '##strategy-title',
      '##ins-title',
      '##strategy',
    ];

    let finalObj = { sections: [], tabsData: [] };

    let isOverview = false;
    let isSection = false;
    let isInstrument = false;
    let i = 0;
    let sectionIndex = -1;
    let instrumentIndex = -1;
    let insSectionIndex = -1;
    let currentInsId = '';

    while (i < a.length) {
      //   console.log(a[i]);
      if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() === '##overview') {
        isOverview = true;
        isSection = false;
        isInstrument = false;
      }
      if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() === '##instrument') {
        isOverview = false;
        //isSection = false;
        isInstrument = true;
        instrumentIndex++;
        finalObj['tabsData'][instrumentIndex] = {
          id: '',
          label: '',
          sections: [],
          title: '',
          subtitle: 'Trading Strategy',
          description: '',
          status: { label: 'Draft', value: 'draft' },
          entry: '',
          stop: '',
          target: '',
          order: '',
        };
      }
      if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() === '##section') {
        isOverview = false;
        isSection = true;
        if (isInstrument) {
          insSectionIndex++;
          finalObj['tabsData'][instrumentIndex]['sections'][insSectionIndex] = { ...sectionObj };
        } else {
          sectionIndex++;
          finalObj['sections'][sectionIndex] = { ...sectionObj };
        }
      }

      if (
        mykeys.includes(sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim()) &&
        sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() != '##section' &&
        sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() != '##overview' &&
        sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() != '##instrument'
      ) {
        let data = getUptoNextTag(i + 1);

        if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() === '##id') {
          let tempobj = this.props.instruments.find((obj) => obj.id == sanitizeHtml(data[0], sanitizeConf));
          if (tempobj != undefined) {
            finalObj['tabsData'][instrumentIndex]['id'] = tempobj.id;
            finalObj['tabsData'][instrumentIndex]['label'] = tempobj.name;
            finalObj['tabsData'][instrumentIndex]['order'] = tempobj.order;
            finalObj['tabsData'][instrumentIndex]['dtn_name'] = tempobj.dtn ? tempobj.dtn.dtn_name : '';
            finalObj['tabsData'][instrumentIndex]['dtn_code'] = tempobj.dtn ? tempobj.dtn.dtn_code : '';
            insSectionIndex = -1;
            currentInsId = tempobj.id;
          } else {
            currentInsId = '';
          }
        }
        if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() === '##title') {
          if (isOverview) {
            let temp = sanitizeHtml(data[0], sanitizeConf);
            finalObj['ov_title'] = $('<div/>').html(temp).text();
          } else if (isSection) {
            if (isInstrument) {
              if (insSectionIndex >= 0 && currentInsId !== '') {
                let temp = sanitizeHtml(data[0], sanitizeConf);
                finalObj['tabsData'][instrumentIndex]['sections'][insSectionIndex]['title'] = $('<div/>')
                  .html(temp)
                  .text();
              }
            } else {
              if (sectionIndex >= 0) {
                let temp = sanitizeHtml(data[0], sanitizeConf);
                finalObj['sections'][sectionIndex]['title'] = $('<div/>').html(temp).text();
              }
            }
          }
        }
        if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() === '##body') {
          //console.log("body data", data);
          if (isOverview) {
            finalObj['ov_description'] = data[0];
          } else if (isSection) {
            if (isInstrument) {
              if (insSectionIndex >= 0 && currentInsId !== '') {
                finalObj['tabsData'][instrumentIndex]['sections'][insSectionIndex]['rows'] = [
                  {
                    columns: [
                      {
                        colSize: 12,
                        colValue: data[0],
                        colType: 'editor',
                      },
                    ],
                  },
                ];
              }
            } else {
              if (sectionIndex >= 0) {
                finalObj['sections'][sectionIndex]['rows'] = [
                  {
                    columns: [
                      {
                        colSize: 12,
                        colValue: data[0],
                        colType: 'editor',
                      },
                    ],
                  },
                ];
              }
            }
          }
        }

        if (currentInsId !== '') {
          if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() == '##ins-title') {
            let temp = sanitizeHtml(data[0], sanitizeConf);
            finalObj['tabsData'][instrumentIndex]['title'] = $('<div/>').html(temp).text();
          }
          if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() == '##strategy-title') {
            let temp = sanitizeHtml(data[0], sanitizeConf);
            finalObj['tabsData'][instrumentIndex]['subtitle'] = $('<div/>').html(temp).text();
          }
          if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() == '##strategy') {
            finalObj['tabsData'][instrumentIndex]['description'] = data[0];
          }
          if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() == '##entry') {
            let temp = sanitizeHtml(data[0], sanitizeConf);
            if (!isNaN(temp)) {
              finalObj['tabsData'][instrumentIndex]['entry'] = parseFloat(temp);
            }
          }
          if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() == '##stop') {
            let temp = sanitizeHtml(data[0], sanitizeConf);
            if (!isNaN(temp)) {
              finalObj['tabsData'][instrumentIndex]['stop'] = parseFloat(temp);
            }
          }
          if (sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim() == '##target') {
            let temp = sanitizeHtml(data[0], sanitizeConf);
            if (!isNaN(temp)) {
              finalObj['tabsData'][instrumentIndex]['target'] = parseFloat(temp);
            }
          }
        }
        i = data[1];
      }
      //   let data = getUptoNextTag(i)
      //   let j=i;
      //   while(i < a.length && a[i+1] !== "##section") {

      //   }
      //   if(a[i] === "##title" && !mykeys.includes(a[i+1])) {
      //     i++;
      //     if(isOverview) {
      //       finalObj["ov_title"] = a[i];
      //     }
      //   }
      //   if(a[i] === "##body" && !mykeys.includes(a[i+1])) {
      //     i++;
      //     if(isOverview) {
      //       finalObj["ov_description"] = a[i];
      //     }
      //   }
      // //   if(a[i] === "##title" && !mykeys.includes(a[i+1])) {
      // //     i++;
      // //     if(isOverview) {
      // //       finalObj["ov_title"] = a[i];
      // //       finalObj["ov_description"] = a[i];
      // //     }
      // //   }
      i++;
    }

    function getUptoNextTag(b) {
      let i = b;
      let st = '';
      //console.log("i = ", i);
      while (i < a.length) {
        if (!mykeys.includes(sanitizeHtml(a[i], sanitizeConf).toLowerCase().trim())) {
          //console.log(a[i], i);
          if (a[i + 1] == '') {
            st += a[i].trim() == '&nbsp;' ? '' : `<p>${a[i]}</p>`;
          } else {
            st += a[i];
          }
        } else {
          break;
        }
        i++;
      }
      return [st, i - 1];
    }
    finalObj['tabsData'] = finalObj['tabsData'].filter((item) => item.id !== '');
    this.props.confirmText(finalObj);
    this.setState({ editorData: '' });
    this.props.close();
  };

  closeEditor = () => {
    this.setState({ editorData: '' });
    this.props.close();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          ariaHideApp={false}
          className={{
            base: 'modal-base',
            afterOpen: 'modal-base_after-open',
            beforeClose: 'modal-base_before-close',
          }}
          portalClassName={'paste-modal'}
        >
          <div className="modal-body">
            <div>
              {!isSSR && (
                <CKEditor
                  data={this.state.editorData}
                  type="classic"
                  config={{ allowedContent: true, contentsCss: '/ck.css', forcePasteAsPlainText: false }}
                  onChange={(event) => {
                    this.setState({ editorData: event.editor.getData() });
                  }}
                />
              )}
            </div>
          </div>
          <div className="modal-base-footer my-2 text-right">
            <button className="btn btn-primary" onClick={this.handleData} type="button">
              Update
            </button>
            <button className="btn btn-danger mx-2" onClick={this.closeEditor} type="button">
              close
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PasteModal;
